.content-format {
    padding: 1rem;
    border-bottom-right-radius: .75rem;
    border-bottom-left-radius: .75rem;
    border-top-right-radius: .75rem;
    background-color: rgba(226, 232, 240, 1);

}

.content-format-grupos {
    margin: 1rem auto auto;
    padding: 2rem;
    border-bottom-right-radius: .75rem;
    border-bottom-left-radius: .75rem;
    border-top-right-radius: .75rem;
    background-color: rgba(226, 232, 240, 1);
    display: initial;
}

.content-seccion_input {
    margin-top: 1rem;
    width: 20rem;
    display: flex;
    border-top-left-radius: .75rem;
    border-top-right-radius: .75rem;
    border: solid 2px rgba(226, 232, 240, 1);
    background-color: rgb(255, 255, 255);
}

.input-section {
    border: none;
    border-top-left-radius: .75rem;
    padding-left: 1rem;
    background-color: #fff
}

@media screen and (max-width: 600px) {
    .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.css-sghohy-MuiButtonBase-root-MuiButton-root.btn-add-group {
        font-size: 0.7rem;
    }

    .content-seccion_input {
        width: 15rem;
    }

}

@media screen and (max-width:560px) {
    .content-format {
        padding: 0.5rem;
    }
}