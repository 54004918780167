.content-clients{
    margin-bottom: 3rem;

}
.content-Inspection_title{
    margin-top: 1rem;
    margin-bottom:1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

}