.content-btn{
    margin-top: 2.5rem;

    display:  flex;
    justify-content: space-evenly;
}
.titleModal{
    font-weight: bold;
    font-size:   1.4rem;
    display: flex;
    justify-content: center;
}
.textModal{
    display: flex;
    justify-content: center;
    font-size: 1.2rem;
}