@font-face {
  font-family: fontGTWalsheimPro;
  src: url(./GTWalsheimPro-Regular.ttf);
}

:root * {
  font-family: fontGTWalsheimPro, sans-serif;
}

body {
  background-color: #f9fafb !important;
}

#root{
  background-color: #f9fafb !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*responsive text */



