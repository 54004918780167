.content-btnList {
    display: flex;
    align-items: center;
    margin: 1rem 0 0 auto;
}

.content-btnList__button {
    margin: 1rem;
}

@media screen and (max-width:500px) {
    .content-btnList {
    flex-wrap: wrap;
    }
    .buttonList-observer{
        margin-top: 0.8rem;
        margin-right: 0.8rem;
    }
    .content-btnList__button{
        margin: 0.1rem;
    }
}