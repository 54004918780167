@media screen and (max-width: 600px) {
    .MuiBox-root.css-1v5u2yv.dashboard-main {

        padding: 0.8rem;
    }




}




@media screen and (max-width: 560px) {


    .MuiTypography-root.MuiTypography-body1.MuiBreadcrumbs-root.css-63vwl1-MuiTypography-root-MuiBreadcrumbs-root.content1-breadcrumb,
    .MuiTypography-root.MuiTypography-body1.css-1gqyz35-MuiTypography-root.content2-breadcrumb {
        font-size: 0.7rem;
    }


    .MuiTypography-root.MuiTypography-h6.css-1s4ev7b-MuiTypography-root.Appbar-title {
        display: none;
    }

    .MuiToolbar-root.MuiToolbar-regular.css-ztizd3-MuiToolbar-root.dashboard-appBar {
        justify-content: space-between;
    }

}