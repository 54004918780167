.button-format_modal {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 1rem;
    border-radius: 1rem;
    width: 22rem;
}

.button-format_modal_finalizar {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 1rem;
    border-radius: 1rem;
    width: 65rem;
    
}

.content-modal_sendFormat {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*background-color: #fff;*/
    border-radius: 1rem;
    width: 15rem;
    padding: 2rem;
}

