.rs-picker-daterange .rs-picker-toggle {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 15px !important;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
  }
  .rs-picker-daterange .rs-picker-toggle:hover {
    border-color: #3f51b5 !important;;
  }
  .rs-picker-daterange .rs-picker-toggle:focus {
    border-color: #3f51b5 !important;
    box-shadow: 0 0 0 2px rgba(63, 81, 181, 0.2);
  }

  .rs-picker-error>.rs-picker-input-group:focus-within {
    border-color: #3f51b5 !important;
  }
  .rs-picker-error>.rs-picker-input-group, .rs-picker-error>.rs-picker-input-group:hover{
    border-color: #3f51b5 !important;
  }
  .rs-input-group-lg.rs-input-group>.rs-input, .rs-input-group-lg.rs-input-group>.rs-input-group-addon{
    height: 60px !important;
  }