.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.css-1gljwbd-MuiButtonBase-root-MuiIconButton-root,
.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.css-1vp8cnp-MuiButtonBase-root-MuiIconButton-root {

    display: none;
}

.css-149ryfa,
.css-1nwp3l9 {
    display: none !important;
}

@media screen and (max-width: 600px) {

    .css-kjirbw-MuiButtonBase-root-MuiButton-root,
    .css-ke5b6m-MuiButtonBase-root-MuiButton-root {
        font-size: 0.5rem !important;
        min-width: 0 !important;
        padding: 2px 10.5px !important;
    }


    .css-149ryfa,
    .css-1nwp3l9 {

        display: inline-flex !important;
    }

    .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.css-1gljwbd-MuiButtonBase-root-MuiIconButton-root,
    .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.css-1vp8cnp-MuiButtonBase-root-MuiIconButton-root {

        display: inline-flex;
    }

    .css-1eiaqm7 {
        justify-content: space-around;
    }

    .MuiBox-root.css-sq9qdz {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .MuiBox-root.css-sq9qdz>.MuiBox-root.css-di3982 {

        /*width: 350px;*/
        justify-content: center;
    }

    .adaptedTable>.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation2.css-1w2l4zy-MuiPaper-root>.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-dense.css-xcubvc-MuiToolbar-root {
        overflow-x: auto;
    }

    .headTable-btn_offline,
    .headTable-btn_liberar,
    .headTable-btn_sync {
        font-size: 0.5rem !important;
        min-width: 0 !important;
        padding: 2px 10.5px !important;
    }

}


@media screen and (max-width: 560px) {

    /*headTalbe*/
    .MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignLeft.MuiTableCell-sizeMedium.css-1tmhgfb-MuiTableCell-root {}


    .MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignLeft.MuiTableCell-sizeMedium.css-1sglxbj-MuiTableCell-root {}


    .css-9l5vo-MuiCollapse-wrapperInner {
        width: auto !important;
    }

    .css-15nixvh {


        width: 3rem !important
    }

    .MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignLeft.MuiTableCell-sizeMedium.css-3zst5y-MuiTableCell-root {

        font-size: 0.7rem;
    }

    .css-1qn7n4p,
    .css-1d4tkm {
        width: auto !important;
        font-size: 0.7rem !important;
    }

    /*bodytable*/

    .MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.css-khbh2j-MuiTableCell-root {
        width: auto;
    }

    .MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.css-1p30kzp-MuiTableCell-root {
        width: auto;
    }

    .MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.css-60d7g7-MuiTableCell-root {
        width: auto;
    }

    .MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.css-sooi30-MuiTableCell-root {
        width: auto;
    }

    .MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.css-sooi30-MuiTableCell-root {
        width: auto;
    }
}