.content-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;

}

.content-data {

    height: 100vh;
}

.txt-format {
    display: block;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.content-formats {
    display: flex;

}

@media screen and (max-width: 560px) {

    .technical-exportInspect,
    .technical-finishInspect,
    .technical-continueInspect,
    .technical-initInspect,
    .technical-cancelInspect {
        padding: 0.2rem !important;
        min-width: none !important;
        font-size: 0.5rem !important;
    }
}