.content-group {
    margin: auto;
    padding: 1rem;
    border-bottom-right-radius: .75rem;
    border-bottom-left-radius: .75rem;
    border-top-right-radius: .75rem;
    background-color: rgb(209, 215, 223);
}

.content-group_input {
    margin-top: 2rem;
    width: 60rem;
    display: flex;


    border-top-left-radius: .75rem;
    border-top-right-radius: .75rem;

    background-color: rgb(255, 255, 255);

}

.input-group {
    border-top-left-radius: .75rem;
    padding-left: 1rem;
    border: none;
    background-color: #fff
}

.subcampo {

    margin-left: 2rem;
}


@media screen and (max-width: 600px) {
    .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.css-sghohy-MuiButtonBase-root-MuiButton-root.btn-add-param {
        font-size: 0.7rem;
    }

    .content-group {
        padding: 0.5rem
    }

    .content-group_input {
        width: 15rem;
    }


}