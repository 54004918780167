.modalImg_box {
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: #fff;
    border: 2px solid #000;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    padding: 32px;
}

.modalImg_box-img {
    max-width: 75rem;
    height: 100%;
}

@media screen and (max-width: 560px) {

    .modalImg_box {
        width: 95%;
        height: 40%;
        padding: 12px;
    }

}