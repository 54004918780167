@media screen and (max-width: 560px) {
  .MuiTypography-root.MuiTypography-h4.css-obo6e8-MuiTypography-root.headerFlat-title {
    font-size: 1.3rem;
    margin: 0.8rem;
    text-align: center;
  }

  .headerFlat-span,
  .headerFlat-span span {
    font-size: 0.8rem;
  }

  .MuiFormControl-root.MuiTextField-root.css-10z7eww-MuiFormControl-root-MuiTextField-root.headerFlat-textField label,
  .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root.headerFlat-textField label {
    font-size: 0.8rem;

  }

}