.content-template {
    display: flex;
    background-color: #F9FAFB;
    flex-direction: column;
    flex: 1 1 auto;
    min-width: 0;

}

.card-cabecera {
    margin-bottom: 2rem;
}

.textInput {
    display: flex;
    padding: 1.5rem;
    align-items: center;
}

.sumCode {
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;

}

.addFormat-grupos {
    margin: 2rem
}

.content-icon_delete {

    color: red;
}

.content-icon_edit {

    color: blue;

}

.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.css-sghohy-MuiButtonBase-root-MuiButton-root.addformat-update-button,
.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.css-sghohy-MuiButtonBase-root-MuiButton-root.addformat-send-button {
    margin: 1rem 0.3rem;
}




@media screen and (max-width: 600px) {
    .addFormat-grupos {
        margin: 0.3rem;
        margin-top: 2rem;
    }

    .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.css-sghohy-MuiButtonBase-root-MuiButton-root.addformat-button {
        font-size: 0.7rem;
    }

    .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.css-sghohy-MuiButtonBase-root-MuiButton-root.addformat-send-button {

        font-size: 0.7rem;
    }

    .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.css-sghohy-MuiButtonBase-root-MuiButton-root.addformat-update-button {
        font-size: 0.7rem;
    }
}


@media screen and (max-width: 560px) {

    .MuiTypography-root.MuiTypography-body1.MuiBreadcrumbs-root.css-63vwl1-MuiTypography-root-MuiBreadcrumbs-root.addFormat-breadcrumb,
    .MuiTypography-root.MuiTypography-body1.css-1gqyz35-MuiTypography-root.addFormat-breadcrumb-typography {
        font-size: 0.7rem;
    }

    .MuiTypography-root.MuiTypography-h4.css-5lbw0b-MuiTypography-root.addFormat-title {
        font-size: 1.4rem;
    }

    .MuiContainer-root.MuiContainer-maxWidthXl.css-fnbicu-MuiContainer-root.addFormat-section {
        padding-left: 0;
        padding-right: 0;
    }


}

/*MuiTypography-root MuiTypography-body1 MuiBreadcrumbs-root addFormat-breadcrumb css-63vwl1-MuiTypography-root-MuiBreadcrumbs-root*/