.content_img {
    border: solid 1px black;
    padding: 8px;
    max-width: 22rem;
}

.content-img-image,
.content-img-image img {
    width: 100%;
}

.content_text {
    display: flex;

}