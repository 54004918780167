/* MuiInputLabel-root MuiInputLabel-animated MuiFormLabel-colorPrimary myclient-label css-9npbnl-MuiFormLabel-root-MuiInputLabel-root*/
.myclient-input {
   width: 20rem;
   margin-left: 0.5rem;
}

.myclient-label {
   margin: 0.5rem
}

@media screen and (max-width: 560px) {
   .myclient-label {
      font-size: 0.7rem;
      margin: 0.5rem
   }

   .myclient-input {
      width: 100%;
      margin-left: 0rem;
      padding: 0.3rem
   }


}