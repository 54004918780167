.contentTogle-ul_li>.section-title {

    display: none;
}

.css-1g86id8-MuiTreeItem-content.MuiTreeItem-content.Mui-selected {
    background-color: rgb(0 99 193 / 25%) !important;
}

.css-yuu8jj.Mui-selected {
    /*for production*/
    background-color: rgb(0 99 193 / 25%) !important;
}


.contentToggle-ul {
    align-items: center;

    display: flex;

}

.ListFlat-li {
    width: 20%;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
}

.ListFlat-li2 {
    width: 60%;
    margin-right: 0.8rem;
    margin-left: 0.8rem;

}

@media screen and (max-width: 600px) {

    .ListFlat-li2,
    .ListFlat-li {

        margin-left: 0;
        margin-right: 0;
    }

    .ListFlat-TitleTable {
        font-size: 0.8rem;
    }

    .ListFlat-SubTitleTable {
        display: block;
        font-size: 0.7rem;
    }

    .contentToggle {
        font-family: 'Lato';
        -webkit-font-smoothing: antialiased;
        -moz-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizelegibility;
    }


    .contentToggle-ul {

        display: flex;
        min-height: 83vh;
        max-height: 83vh;
        margin: 0;
        padding: 0;
        margin-top: 3rem;
        /* overflow: hidden;*/

    }

    .contentTogle-ul_li {
        justify-content: center;
        display: flex;
        align-items: stretch;
        background: #1976d2;
        height: 83vh;
        width: 100%;
        border-radius: 5px;
        border: 0.1rem solid #fff;



        cursor: pointer;
        transition: all 0.5s ease;
    }

    .contentTogle-ul_li:hover {
        background: #0955a1;
        ;
    }

    .contentTogle-ul_li.active {

        background: #fff;
        cursor: default;
    }

    .contentTogle-ul_li.active .section-subSpan {

        display: none;
    }

    .contentTogle-ul_li.active .section-content {
        display: flex;
        transition: all 0.25s 0.1s ease-out;
        width: 24rem;
        margin: 1rem;
        opacity: 1;
        transform: scaleX(1);

    }

    .contentTogle-ul_li .section-content .content-displayind,
    .contentTogle-ul_li.active>.section-title {
        display: none;
    }

    .contentTogle-ul_li.active .section-content .content-displayind {
        display: block;
        width: 100%;
    }

    .contentTogle-ul_li>.section-title {
        /* flex: 1 1; */
        display: flex;
        align-items: center;
        /* width: 100%; */
        margin: 0;
        /* width: 2px; */
        padding: 0;
        text-align: center;
        justify-content: center;
        color: white;
        width: 1rem;
    }

    .contentTogle-ul_li>.section-title .section-subSpan {
        margin: 0;
        transform: rotate(-90deg);
        white-space: nowrap;
    }

    .contentTogle-ul_li>.section-content {

        display: flex;
        display: none;
        align-items: center;
        margin: 0;
        padding: 0;
        opacity: 0;
        transition: all 0.25s 0.1s ease-out;
    }
}


@media screen and (max-width: 560px) {
    .ListFlat-btnAction {
        font-size: 0.5rem !important;
        min-width: none !important;
        padding: 0.2rem !important;
    }

    .MuiTreeItem-label {
        font-size: 0.7rem !important;
    }

    .MuiTableRow-root.MuiTableRow-head.css-suriyt-MuiTableRow-root {
        font-size: 0.7rem;
        padding: 0.3rem;
    }

    .css-1oqqzyl-MuiContainer-root {
        padding: 0 !important;
        margin: 0 !important;
    }

    .MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.css-sooi30-MuiTableCell-root {
        font-size: 0.7rem;
        padding: 0.3rem;
    }


    .contentTogle-ul_li.active .section-content {
        width: 20rem;

    }

    .contentTogle-ul_li.active .section-content {
        margin: 0.3rem;
    }

    .contentTogle-ul_li>.section-title .section-subSpan {
        font-size: 0.8rem;
    }

    .headerFlat-textField {
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 380px) {
    .contentTogle-ul_li.active .section-content {
        width: 14rem;

    }
}