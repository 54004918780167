.ParamsData-container {
    overflow-y: auto;
    margin: 1rem;
    padding: 1.5rem;
    border: solid 2px #000;
    border-radius: 0.5rem;
    height: 70%;
}

@media screen and (max-width: 560px) {

    .ParamsData-container {
        margin: 0.3rem;
        padding: 0.3rem;

    }

    .ParamsData-button {
        font-size: 0.5rem !important;
        min-width: none !important;
        padding: 0.2rem !important;
    }


}