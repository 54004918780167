.contentWelcome {

    margin: auto;
    margin-top: 3rem;
    -webkit-border-radius: 24px;
    border-radius: 24px;
    -webkit-box-shadow: 5px 5px 69px #a8a8a8, -5px -5px 69px #ffffff;
    box-shadow: 5px 5px 69px #a8a8a8, -5px -5px 69px #ffffff;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.contentInspecciones {
    width: 100%;
    background-image: url("/public/img/inspecciones.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.contentWelcome__box {
    padding: 1.5rem;
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.contentWelcome__text {
    text-align: center;

}

.contentWelcome__text span {
    font-size: 22px;
    display: table;
    margin-left: auto;
    margin-right: auto;
}

.contentWelcome__text h2 {
    font-size: 2.5rem;
    font-weight: bold;
}

@media screen and (max-width: 600px) {
    .contentWelcome {

        flex-direction: column-reverse;

    }

    .contentWelcome__text h2 {
        font-size: 2em;
        font-weight: bold;
        color: rgb(0, 70, 106)
    }

    .contentWelcome__text span {
        font-size: 0.9em;
        font-weight: bold;
        color: rgb(0, 39, 59)
    }


}

@media screen and  (max-width: 1600px) {
    .contentWelcome__box {
        color: #373737;
    }
}


@media screen and (max-width: 600px) {
    .contentWelcome__box {
        padding: 0;
    }
}
    
