.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.css-sghohy-MuiButtonBase-root-MuiButton-root.trayInspection-button {

    margin: 1rem 0.3rem;
}

.TrayInspection-table_txt {
    font-weight: bold;
}

@media screen and (max-width: 600px) {

    .MuiButtonBase-root.Mui-disabled.MuiButton-root.MuiButton-contained.MuiButton-containedSuccess.MuiButton-sizeMedium.MuiButton-containedSizeMedium.css-ke5b6m-MuiButtonBase-root-MuiButton-root.headTable-btn_offline,
    .MuiButtonBase-root.Mui-disabled.MuiButton-root.MuiButton-contained.MuiButton-containedInfo.MuiButton-sizeMedium.MuiButton-containedSizeMedium.css-kjirbw-MuiButtonBase-root-MuiButton-root.headTable-btn_liberar,
    .MuiButtonBase-root.Mui-disabled.MuiButton-root.MuiButton-contained.MuiButton-containedInfo.MuiButton-sizeMedium.MuiButton-containedSizeMedium.css-kjirbw-MuiButtonBase-root-MuiButton-root.headTable-btn_sync {
        font-size: 0.7rem;
        min-width: 0;
        padding: 4px 14.5px;

    }


    .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.css-sghohy-MuiButtonBase-root-MuiButton-root.trayInspection-button {
        font-size: 0.7rem;
        min-width: 0;
        padding: 4px 14.5px;
    }

    /*  font-size: 0.5rem;
  
    padding: 3px 6px;
    border-radius: 4px;
*/

}

/*6-16*/

@media screen and (max-width: 560px) {


    .MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.css-95u3r1-MuiTableCell-root {
        font-size: 0.7rem;
        padding: 0.3rem;
    }






    .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.css-sghohy-MuiButtonBase-root-MuiButton-root.trayInspection-button {
        font-size: 0.5rem;
        min-width: 0;
        padding: 2px 10.5px;
    }

    .MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.css-sooi30-MuiTableCell-root {
        padding: 0;
        width: 0;
    }


}